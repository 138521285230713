<template>
  <div id="about-editors">
    <h1>{{ content.title }}</h1>
    <Toc v-if="content.generateToc" :items="tocItems"></Toc>
    <div class="cms-content" v-html="content.content"></div>
  </div>
</template>

<script>
import StaticContent from "@/mixins/StaticContent.vue"
import { gql } from "apollo-boost"
import Toc from "@/components/base/Toc.vue"

export default {
  name: "Editors",
  mixins: [StaticContent],
  components: {
    Toc
  },
  apollo: {
    content: {
      query: gql`
        query getEditorPage {
          editor {
            data {
              attributes {
                title
                content
                generateToc: toc
              }
            }
          }
        }
      `,
      update: (data) => data.editor.data.attributes
    }
  }
}
</script>

<style scoped lang="scss">
#about-editors {
  img {
    display: block;
    width: 100%;
    max-width: 628px;
    max-height: 330px;
    margin: 0 auto;
  }
}
</style>
